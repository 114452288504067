import "@css/app.css";

import "lazysizes";

// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

const productSlider = new Swiper(".product-slider", {
  slidesPerView: "auto",
  spaceBetween: 0,
});

if (window.innerWidth < 1024) {
  const gallarySlider = new Swiper(".gallary-slider", {
    slidesPerView: 1.5,
    spaceBetween: 15,
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
} else if (window.innerWidth < 1600) {
  const gallarySlider = new Swiper(".gallary-slider", {
    slidesPerView: 2,
    spaceBetween: 15,
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
} else {
  const gallarySlider = new Swiper(".gallary-slider", {
    slidesPerView: 3,
    spaceBetween: 24,
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
}
//check how many .slide elements are in the .images-slider
let numberOfSlides = document.querySelectorAll(
  ".images-slider .swiper-slide"
).length;
if (numberOfSlides > 1) {
  const imagesSlider = new Swiper(".images-slider", {
    slidesPerView: 1.33,
    spaceBetween: 10,
    breakpoints: {
      1024: {
        init: false,
        allowTouchMove: false,
      },
    },
  });
}

$(window).load(function () {
  $("body").removeClass("preload");
});
